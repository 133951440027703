.node {
  &.node-full {
    .field-name-field-fc-feature {
      .field-item {
        min-height: 25rem;
        h4 {
          margin: 0 0 1.5rem;
          padding: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  &.node-partners.node-token,
  &.node-offer.node-token {
    overflow: hidden;
    .content {
      position: relative;
      overflow: hidden;
      display: block;
      &:before {
        position: absolute;
        display: block;
        content: ' ';
        width: 100%;
        height: 100%;
        @include background-opacity(black,0.3);
      }
    }
    .desc {
      padding:0;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      color: white;
      .desc-wrap {
        padding: 36% 3rem;
        text-align: center;
      }
      .body-short {
        opacity: 0;
        visibility: hidden;
      }
    }
    .desc,
    .desc-wrap,
    .body-short {
      @include transition(0.5s);
    }
    &:hover {
      .desc {
        background: $orange;
      }
      .desc-wrap {
        padding: 15% 3rem;
        text-align: center;
      }
      .body-short {
        opacity: 1;
        visibility: visible;
      }
    }
    h3 {
      font-size: $font-header - 4px;
      line-height: $font-header;
      font-weight: 700;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      word-wrap: break-word;
      @include text-shadow(0,0,5px,rgba(0,0,0,.6));
    }
  }
  h6 {
    font-weight: 700;
    font-size: $font-base;
    text-transform: uppercase;
  }
  ul.marked-list {
    display: block;
    margin: 2rem 0;
    list-style: none;
    padding: 0;
    li {
      display: block;
      position: relative;
      padding-left: 2.5rem;
      margin-bottom: 0.75rem;
      &:before {
        display: block;
        position: absolute;
        left: 0;
        top: 0.4rem;
        content: '\f18e';
        color: $orange;
        @include fontAwesome;
      }
    }
  }
}
.gallery-item .node-partners {
  border: 1px solid $gray-white;
  background: white;
  margin-bottom: 4rem;
  &.node-token {
    background: none;
    border: none;
    text-align: center;
    img {
      display: inline-block;
    }
  }
  .logo-thumb {
    overflow: hidden;
  }
  .desc {
    padding: 2rem;
  }
  h3 {
    margin: 0 0 2rem;
    font-weight: 700;
    color: $orange;
  }
  p {
    height: 9.5rem;
    overflow: hidden;
  }
  img {
    @include transition(all 0.4s ease 0s);
  }
  &:hover {
    img {
      transform: scale(1.2) rotate(-2deg);
      opacity: 0.8;
    }
  }
}