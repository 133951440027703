@media (min-width: 768px) and (max-width: 991px) {

}

@media (max-width: 991px) {
  #block-bannertop-my-bannertop #block-bannertop-slider {
    .banner-slider {
      .box-wraper {
        margin-top: 1rem;
        h5 {
          font-size: $font-header-big * 0.6;
          margin: 0 0 5px;
        }
        p {
          font-size: $font-header-med * 0.6;
          margin: 5px 0;
        }
      }
    }
  }
}


