a {
  @include transition(all 0.4s ease 0s);
  text-decoration: none !important;
  outline: none !important;
  color: $hover-color;
  &:hover {
    color: darken($hover-color,10%);
  }
}
h1 {
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  margin: 2rem 0;
}