$font-base: 17px;
$font-header-small: 20px;
$font-header-med: 25px;
$font-header: 30px;
$font-header-big: 50px;

@import url('https://fonts.googleapis.com/css?family=Raleway:400,600');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&subset=latin-ext');
.font-style-r {
  font-family: 'Raleway', sans-serif;
}
.font-style-oc {
  font-size: $font-base;
  font-family: 'Open Sans Condensed', sans-serif;
}
