.node-employee {
  &.node-teaser {
    h3 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: $font-header-small;
      font-weight: 600;
      color: white;
    }
  }
}