.contact-info {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  h5 {
    font-weight: 700;
    font-size: $font-base;
    text-transform: uppercase;
    color: $orange;
  }
  p {
    font-weight: 400;
    text-transform: inherit;
  }
  li {
    p + dl {
      margin-top: 3px;
      margin-bottom: 3px;
    }
    dt,
    dd {
      display: inline-block;
      font-weight: 400;
    }
    dt {
      min-width: 100px;
      vertical-align: top;
      &:after {
        content: ":";
      }
    }
    /*
    dl:last-child dt {
      min-width: auto;
    }
    */
    a:hover {
      color: #f7682c;
    }
    a[href^="mailto"] {
      color: #f7682c;
      text-decoration: underline;
    }
    a[href^="mailto"]:hover {
      color: #2e2e2e;
    }
    @media (min-width: 768px) {
      dt {
        min-width: 100px;
      }
    }
  }
}