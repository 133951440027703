#footer {
  background: $black;
  color: $black-font;
  font-size: $font-base - 3px;
  h3 {
    color: white;
    font-size: $font-base + 2px;
    text-transform: uppercase;
  }
  padding: 1rem 0;
}
#footer-columns {
  background: url('../logo-bottom.svg') no-repeat right 3rem;
}
#block-footerinfo-footerinfo {
  .item {
    i, span {
      color: $orange;
    }
  }
  ul.menu {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      display: block;
      margin: 0 0 0.5rem;
      padding: 0;
      ul {
        display: none !important;
        visibility: hidden !important;
        height: 0 !important;
      }
      a {
        color: white;
        display: inline-block;
        &:before {
          @include transition(all 0.4s ease 0s);
          @include fontAwesome();
          content: '\f18e';
          margin-right: 1rem;
          color: darken($black-font, 20%);
        }
        &:hover {
          &:before {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
}