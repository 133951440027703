@mixin clearfix {
  &:before,
  &:after {
    content: ".";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
    float: none;
  }
}