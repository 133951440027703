.field-collection-container {
  margin: 0;
  padding: 0;
  border: 0;
}
.field-collection-view {
  margin: 0;
  padding: 0;
  border: 0;
}
.columns-x3 {
  h5 {
    font-weight: 700;
    text-transform: uppercase;
    font-size: $font-header-med;
    margin-bottom: 2rem;
  }
  strong, time {
    font-weight: 700;
    color: $orange;
    margin: 1rem 0;
  }
  ul.marked-list {
    display: block;
    margin: 2rem 0;
    list-style: none;
    padding: 0;
    li {
      display: block;
      position: relative;
      padding-left: 2.5rem;
      margin-bottom: 0.75rem;
      &:before {
        display: block;
        position: absolute;
        left: 0;
        top: 0.4rem;
        content: '\f18e';
        color: $orange;
        @include fontAwesome;
      }
    }
  }
  .body-content {
    margin: 2rem 0;
  }
}
// ----------------------------------
.field-name-field-fc-feature {
  .field-items {
    display: flex;
    flex-wrap: wrap;
  }
  .field-item {
    flex: 1 0 50%;;
    &:nth-child(odd) {
      padding-right: 2rem;
    }
    &:nth-child(even) {
      padding-left: 2rem;
      margin-bottom: 2rem;
    }
    h4 {
      margin: 0 0 4rem;
      display: block;
      padding-left: 8rem;
      position: relative;
      color: $orange;
      text-transform: uppercase;
      font-size: $font-base;
      text-transform: uppercase;
      font-weight: 700;
      display: inline-block;
      &:before {
        margin: 0;
        padding: 0;

        display: block;
        position: absolute;
        left: 0;
        top: -1rem;
        color: $white-font;
        font-weight: 300;
        overflow: hidden;
        font-size: $font-header-med * 2.5;
        height: $font-header-med * 2.5;
        line-height: $font-header-med * 2.5;
      }
    }
    .desc {
      min-height: 6rem;
    }
  }
  $elements: 9;
  @for $i from 1 to $elements {
    .field-item:nth-child(#{$i}) h4:before {
      content: '0' + $i + '|';
    }
  }
}