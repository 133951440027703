/**
 * $BUTTON
 */
@mixin buton-read-more() {
  position: relative;
  overflow: hidden;
  // ------------------
  &:before,
  &:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    background-color: $black-font;
    height: 50%;
    width: 100%;
    opacity: 0.1;
    overflow: hidden;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
  }
  &:before {
    top: 100%;
  }
  &:after {
    bottom: 100%;
  }
  // ------------------
  &:hover::before,
  &:hover::after {
    opacity: 0.1;
    cursor: pointer;
  }

  &:hover::before {
    @include transform(translateY(-100%) translateZ(0));
  }

  &:hover::after {
    @include transform(translateY(100%) translateZ(0));
  }
}

@mixin buton-read-more-invert() {
  position: relative;
  overflow: hidden;
  color: white;
  background-color: $orange;
  padding: 1rem 3rem;
  font-size: $font-header-small;
  // ------------------
  &:before,
  &:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    background-color: white;
    height: 50%;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  // ------------------
  &:hover {
    color: $orange;
    background: none;
    &::before,
    &::after {
      opacity: 0.5;
      cursor: pointer;
    }
  }

  &:hover::before {
    @include transform(translateY(-100%) translateZ(0));
  }

  &:hover::after {
    @include transform(translateY(100%) translateZ(0));
  }
}