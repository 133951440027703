@media (min-width: 1199px) and (max-width: 1600px) {
  #block-bannertop-my-bannertop #block-bannertop-slider {
    .banner-slider {
      .box-wraper {
        margin-top: 5rem;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  #block-bannertop-my-bannertop #block-bannertop-slider {
    .banner-slider {
      .box-wraper {
        margin-top: 2rem;
        h5 {
          font-size: $font-header-big * 0.8;
        }
        p {
          font-size: $font-header-med * 0.8;
        }
      }
    }
  }

}
@media (max-width: 1199px) {

}


