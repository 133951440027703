#webform-client-form-19 {
  @include placeholder {
    color: darken(white,70%);
  }

  .form-control {
    height: inherit;
    padding: 1.5rem 2rem;

    &:focus {
      @include placeholder {
        font-size: 5px;
        color: darken(white,10%);
      }
    }
  }
  .btn-default {
    margin-top: 1.5rem;
    border: none;
    background: $orange;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: $font-header-small;
    padding: 1.5rem 3rem;
    @include transition(all 0.4s ease 0s);
    &:hover {
      background: lighten($orange, 10%);
    }
  }
}