#nav {
  @include boot-menu('left', $font-base + 1px , $orange , white );
  ul.menu {
    li {
      a {
        padding: 2rem 1.75rem;
        color: white;
        font-weight: 400;
        text-transform: uppercase;
        position: relative;
        &:before {
          display: none !important;
          height: 0 !important;
          display: none;
          position: absolute;
          top: -0.75rem;
          left: 0;
          content: ' ';
          width: 100%;
          height: 0.75rem;
          background: $orange;
          @include transition(all 0.4s ease 0s);
          @include border-radius-separate(0.75rem, 0.75rem, 0 ,0 );
        }
        &.active,
        &:hover {
          background: $black;
          &:before {
            display: block;
          }
        }
      }
      ul {
        background: $black;
        li {
          a {
            text-transform: inherit;
            padding: 1rem 2.25rem 1rem 1.75rem;
            @include transition(all 0.2s ease 0s);
            &:hover {
              color: $orange;
              padding: 1rem 1.75rem 1rem 2.25rem;
            }
            &:before {
              display: none !important;
              height: 0;
            }
          }
        }
      }

    }
  }
}