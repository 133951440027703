@media (max-width: 768px) {
  #block-bannertop-my-bannertop #block-bannertop-slider {
    .banner-slider {
      .box-wraper {
        margin-top: 1rem;
        h5 {
          font-size: $font-header-big * 0.5;
        }
        p {
          font-size: $font-header-med * 0.5;
        }
        .read-more-button-invert {
          padding: 0.5rem 2rem;
          font-size: 100%;
          margin: 1rem 0;
        }
      }
    }
  }

  #box-bottom {
    .box-header-stickup {
      display: none;
    }
  }

  #footer-columns {
    .region-footer-firstcolumn {
      max-width: 60%;
      margin: 0 auto;
    }
  }
  .field-name-field-fc-feature {
    .field-items {
      display: block;
      flex-wrap: inherit;
    }
    .field-item {
      flex: inherit;
      &:nth-child(odd),
      &:nth-child(even) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .node {
    &.node-full {
      .field-name-field-fc-feature {
        .field-item {
          min-height: inherit;
        }
      }
    }
  }
}
@media (max-width: 568px) {
  #block-bannertop-my-bannertop #block-bannertop-slider {
    .banner-slider {
      .box-wraper {
        .read-more-button-invert {
          display: none;
        }
      }
    }
  }

  #footer-columns {
    .region-footer-firstcolumn {
      max-width: 80%;
    }
  }
}

@media (max-width: 468px) {
  #block-bannertop-my-bannertop {
    display: none;
  }
}
