#block-views-bottompage-block {
  .view-content {
    padding: 4rem 0;
  }
}
#block-views-employee-block {
  background: $black;
  color: darken(white,30%);
  padding-top: 4rem;
  padding-bottom: 4rem;
  a {
    color: white;
  }
  .view-header {
    h3, h5 {
      color: white;
      text-transform: uppercase;
      margin: 0 0 0.5rem;
      font-weight: 600;
      font-family: 'Raleway', sans-serif;
    }
    h3 {
      font-size: $font-header-med;
    }
    h5 {
      font-size: $font-header-med - 5;
      color: $orange;
    }
  }
  .view-content {
    margin-top: 2rem;
  }
}


#block-webform-client-block-19 {
  background: $black;
  padding: 4rem 0;
  color: white;
  i {
    font-size: 60px;
  }
  h3 {
    margin: 0.5rem 0 2rem 2rem;
    font-size: $font-header-med;
    font-weight: 700;
    text-transform: uppercase;
  }
}