#header {

}
// #box-top
#box-top {
  background: $grey;
  a, i {
    color: $orange;
  }
  .region-box-top {
    float: left;
    width: 80%;
    @include clearfix;
  };
  .main-email {
    font-size: 90%;
    padding: 0.5rem 0 0;
  }
}
#block-follow-site {
  float: left;
  .follow-link-wrapper {
    display: block;
    float: left;
    a {
      display: block;
      float: none;
      text-indent: -9999px;
      border-left: 1px solid white;
      padding: 0;
      margin: 0;
      width: 37px;
      height: 37px;
      background: none;
      position: relative;

      &:before {
        position: absolute;
        @include fontAwesome;
        display: block;
        width: 33px;
        height: 33px;
        text-indent: 0;
        padding: 0.75rem;
        text-align: center;
      }
      &.follow-link-facebook:before {
        content:"";
      }
      &.follow-link-twitter:before {
        content:"";
      }
      &.follow-link-googleplus:before {
        content:"";
      }
      &.follow-link-youtube:before {
        content:""
      }
    }
    &:last-child a {
      border-right: 1px solid white;
    }
  }
}
#block-locale-language-content {
  float: right;
  .language-switcher-locale-url {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix;
    li {
      margin-top: 0.75rem;
      display: block;
      float: left;
      border-right: 1px solid lighten($black, 50%);
      &:last-child {
        border: none;
      }
      a, span {
        color: $black;
        font-size: 90%;
        display: block;
        padding: 0 1rem 1px;
        &.active {
          color: $orange;
        }
      }
    }
  }
}
// #box-middle
#logo {
  padding: 2rem 0 3rem;
}
#box-middle .main-phone {
  margin-top: 3rem;
  border: 2px solid $grey;
  display: block;
  font-size: $font-header-med;
  line-height: $font-header-med;
  @include border-radius(0.1rem);
  i {
    color: $orange;
    padding: 1.5rem 2rem;
  }
  span {
    padding: 1.5rem 2rem;
    border-left: 2px solid $grey;
  }
}
// #box-bottom
#box-bottom {
  .box-header-stickup {
    background: $orange;
    padding: 0;
    margin: 0;
    &.isStuck {
      z-index: 200;
    }
  }
}
// #block-bannertop-my-bannertop
#block-bannertop-my-bannertop {
  .bx-wrapper,
  .bx-viewport {
    margin: 0;
    padding: 0;
    display: block;
    border: none;
    left: 0;
    @include box-shadow(0, 0, 0, 0, black);
  }
  .banner-slider {
    margin: 0;
    padding: 0;
    display: block;
    list-style: none;
    li {
      img {
        width: 100%;
      }
      .image-sm {
        display: none;
      }
      .description {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: white;
        h5, p {
          font-weight: 700;
          text-transform: uppercase;
          @include text-shadow(0,0,5px,rgba(0,0,0,.6));
        }
        h5 {
          font-size: $font-header-big;
          font-weight: 700;
        }
        p {
          font-weight: 300;
          font-size: $font-header-med;
        }
        .box-wraper {
          text-align: center;
          margin-top: 10rem;
          &.container-fluid {
            width: 80%;
            margin: 4rem auto;
          }
        }

      }
    }
  }
}