@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Raleway:400,600");
@import url("https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700&subset=latin-ext");
.font-style-r {
  font-family: 'Raleway', sans-serif; }

.font-style-oc {
  font-size: 17px;
  font-family: 'Open Sans Condensed', sans-serif; }

/* -------------------------------------------------------------
  Sass CSS3 Mixins! The Cross-Browser CSS3 Sass Library
  By: Matthieu Aussaguel, http://www.mynameismatthieu.com, @matthieu_tweets

  List of CSS3 Sass Mixins File to be @imported and @included as you need

  The purpose of this library is to facilitate the use of CSS3 on different browsers avoiding HARD TO READ and NEVER
  ENDING css files

  note: All CSS3 Properties are being supported by Safari 5
  more info: http://www.findmebyip.com/litmus/#css3-properties

------------------------------------------------------------- */
/**
 * $BUTTON
 */
.a-left {
  float: left; }

.a-right {
  float: right; }

.page-brake-wborder {
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  background: #3b3b3b; }

.read-more-button-invert,
.read-more-button {
  display: inline-block;
  margin: 2rem 0;
  color: white;
  padding: 0.75rem 2rem;
  text-transform: uppercase; }

.read-more-button {
  border: 1px solid #7b7b7b;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 2px;
  -khtml-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px; }
  .read-more-button:before, .read-more-button:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    background-color: #7b7b7b;
    height: 50%;
    width: 100%;
    opacity: 0.1;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -khtml-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .read-more-button:before {
    top: 100%; }
  .read-more-button:after {
    bottom: 100%; }
  .read-more-button:hover::before, .read-more-button:hover::after {
    opacity: 0.1;
    cursor: pointer; }
  .read-more-button:hover::before {
    -webkit-transform: translateY(-100%) translateZ(0);
    -khtml-transform: translateY(-100%) translateZ(0);
    -moz-transform: translateY(-100%) translateZ(0);
    -ms-transform: translateY(-100%) translateZ(0);
    -o-transform: translateY(-100%) translateZ(0);
    transform: translateY(-100%) translateZ(0); }
  .read-more-button:hover::after {
    -webkit-transform: translateY(100%) translateZ(0);
    -khtml-transform: translateY(100%) translateZ(0);
    -moz-transform: translateY(100%) translateZ(0);
    -ms-transform: translateY(100%) translateZ(0);
    -o-transform: translateY(100%) translateZ(0);
    transform: translateY(100%) translateZ(0); }

.read-more-button-invert {
  border: 2px solid #f66838;
  position: relative;
  overflow: hidden;
  color: white;
  background-color: #f66838;
  padding: 1rem 3rem;
  font-size: 20px;
  -webkit-border-radius: 4px;
  -khtml-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px; }
  .read-more-button-invert:before, .read-more-button-invert:after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    background-color: white;
    height: 50%;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -khtml-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .read-more-button-invert:before {
    top: 0; }
  .read-more-button-invert:after {
    bottom: 0; }
  .read-more-button-invert:hover {
    color: #f66838;
    background: none; }
    .read-more-button-invert:hover::before, .read-more-button-invert:hover::after {
      opacity: 0.5;
      cursor: pointer; }
  .read-more-button-invert:hover::before {
    -webkit-transform: translateY(-100%) translateZ(0);
    -khtml-transform: translateY(-100%) translateZ(0);
    -moz-transform: translateY(-100%) translateZ(0);
    -ms-transform: translateY(-100%) translateZ(0);
    -o-transform: translateY(-100%) translateZ(0);
    transform: translateY(-100%) translateZ(0); }
  .read-more-button-invert:hover::after {
    -webkit-transform: translateY(100%) translateZ(0);
    -khtml-transform: translateY(100%) translateZ(0);
    -moz-transform: translateY(100%) translateZ(0);
    -ms-transform: translateY(100%) translateZ(0);
    -o-transform: translateY(100%) translateZ(0);
    transform: translateY(100%) translateZ(0); }

.wall-grid-item {
  display: block;
  width: 25%;
  height: auto;
  float: left;
  overflow: hidden; }

#maps-wraper {
  min-height: 32rem; }

#box-paralax {
  padding-top: 12rem;
  padding-bottom: 12rem;
  color: white;
  background-attachment: fixed;
  background-size: cover; }

#nav {
  background-color: #f66838; }
  #nav .navbar-collapse {
    margin: 0;
    padding: 0;
    border: none;
    overflow-y: hidden;
    float: left; }
    @media (max-width: 992px) {
      #nav .navbar-collapse {
        float: none; } }
    #nav .navbar-collapse > ul.menu > li:hover > ul {
      display: block; }
  #nav ul.menu {
    margin: 0;
    padding: 0;
    display: block; }
    #nav ul.menu:before, #nav ul.menu:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
      float: none; }
    #nav ul.menu li {
      float: left;
      margin: 0;
      padding: 0;
      display: block;
      position: relative; }
      #nav ul.menu li span,
      #nav ul.menu li a {
        font-size: 18px;
        display: block; }
      #nav ul.menu li ul {
        display: none;
        position: absolute;
        z-index: 20;
        -webkit-border-radius: 0;
        -khtml-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        background-color: white;
        /* The Fallback */
        background-color: rgba(255, 255, 255, 0.75);
        -webkit-transition: all 0.4s ease 0s;
        -khtml-transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        -webkit-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2);
        -moz-box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2);
        box-shadow: 0px 5px 10px 0px rgba(50, 50, 50, 0.2); }
        #nav ul.menu li ul li {
          float: none; }
          #nav ul.menu li ul li ul {
            display: none; }
  @media (max-width: 768px) {
    #nav .navbar-collapse,
    #nav ul.menu {
      float: none; }
    #nav ul.menu li {
      float: none; }
      #nav ul.menu li a {
        text-align: center; }
      #nav ul.menu li ul {
        display: block;
        position: inherit; } }
  #nav ul.menu li a {
    padding: 2rem 1.75rem;
    color: white;
    font-weight: 400;
    text-transform: uppercase;
    position: relative; }
    #nav ul.menu li a:before {
      display: none !important;
      height: 0 !important;
      display: none;
      position: absolute;
      top: -0.75rem;
      left: 0;
      content: ' ';
      width: 100%;
      height: 0.75rem;
      background: #f66838;
      -webkit-transition: all 0.4s ease 0s;
      -khtml-transition: all 0.4s ease 0s;
      -moz-transition: all 0.4s ease 0s;
      -ms-transition: all 0.4s ease 0s;
      -o-transition: all 0.4s ease 0s;
      transition: all 0.4s ease 0s;
      -webkit-border-top-left-radius: 0.75rem;
      -webkit-border-top-right-radius: 0.75rem;
      -webkit-border-bottom-right-radius: 0;
      -webkit-border-bottom-left-radius: 0;
      -moz-border-radius-topleft: 0.75rem;
      -moz-border-radius-topright: 0.75rem;
      -moz-border-radius-bottomright: 0;
      -moz-border-radius-bottomleft: 0;
      border-top-left-radius: 0.75rem;
      border-top-right-radius: 0.75rem;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    #nav ul.menu li a.active, #nav ul.menu li a:hover {
      background: #2e2e2e; }
      #nav ul.menu li a.active:before, #nav ul.menu li a:hover:before {
        display: block; }
  #nav ul.menu li ul {
    background: #2e2e2e; }
    #nav ul.menu li ul li a {
      text-transform: inherit;
      padding: 1rem 2.25rem 1rem 1.75rem;
      -webkit-transition: all 0.2s ease 0s;
      -khtml-transition: all 0.2s ease 0s;
      -moz-transition: all 0.2s ease 0s;
      -ms-transition: all 0.2s ease 0s;
      -o-transition: all 0.2s ease 0s;
      transition: all 0.2s ease 0s; }
      #nav ul.menu li ul li a:hover {
        color: #f66838;
        padding: 1rem 1.75rem 1rem 2.25rem; }
      #nav ul.menu li ul li a:before {
        display: none !important;
        height: 0; }

a {
  -webkit-transition: all 0.4s ease 0s;
  -khtml-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-decoration: none !important;
  outline: none !important;
  color: #f66838; }
  a:hover {
    color: #f0450b; }

h1 {
  font-weight: 700;
  font-size: 25px;
  text-transform: uppercase;
  margin: 2rem 0; }

#webform-client-form-19 ::-webkit-input-placeholder {
  color: #4d4d4d; }

#webform-client-form-19 :-moz-placeholder {
  color: #4d4d4d; }

#webform-client-form-19 ::-moz-placeholder {
  color: #4d4d4d; }

#webform-client-form-19 :-ms-input-placeholder {
  color: #4d4d4d; }

#webform-client-form-19 .form-control {
  height: inherit;
  padding: 1.5rem 2rem; }
  #webform-client-form-19 .form-control:focus ::-webkit-input-placeholder {
    font-size: 5px;
    color: #e6e6e6; }
  #webform-client-form-19 .form-control:focus :-moz-placeholder {
    font-size: 5px;
    color: #e6e6e6; }
  #webform-client-form-19 .form-control:focus ::-moz-placeholder {
    font-size: 5px;
    color: #e6e6e6; }
  #webform-client-form-19 .form-control:focus :-ms-input-placeholder {
    font-size: 5px;
    color: #e6e6e6; }

#webform-client-form-19 .btn-default {
  margin-top: 1.5rem;
  border: none;
  background: #f66838;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 20px;
  padding: 1.5rem 3rem;
  -webkit-transition: all 0.4s ease 0s;
  -khtml-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }
  #webform-client-form-19 .btn-default:hover {
    background: #f88d69; }

.field-collection-container {
  margin: 0;
  padding: 0;
  border: 0; }

.field-collection-view {
  margin: 0;
  padding: 0;
  border: 0; }

.columns-x3 h5 {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 2rem; }

.columns-x3 strong, .columns-x3 time {
  font-weight: 700;
  color: #f66838;
  margin: 1rem 0; }

.columns-x3 ul.marked-list {
  display: block;
  margin: 2rem 0;
  list-style: none;
  padding: 0; }
  .columns-x3 ul.marked-list li {
    display: block;
    position: relative;
    padding-left: 2.5rem;
    margin-bottom: 0.75rem; }
    .columns-x3 ul.marked-list li:before {
      display: block;
      position: absolute;
      left: 0;
      top: 0.4rem;
      content: '\f18e';
      color: #f66838;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

.columns-x3 .body-content {
  margin: 2rem 0; }

.field-name-field-fc-feature .field-items {
  display: flex;
  flex-wrap: wrap; }

.field-name-field-fc-feature .field-item {
  flex: 1 0 50%; }
  .field-name-field-fc-feature .field-item:nth-child(odd) {
    padding-right: 2rem; }
  .field-name-field-fc-feature .field-item:nth-child(even) {
    padding-left: 2rem;
    margin-bottom: 2rem; }
  .field-name-field-fc-feature .field-item h4 {
    margin: 0 0 4rem;
    display: block;
    padding-left: 8rem;
    position: relative;
    color: #f66838;
    text-transform: uppercase;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block; }
    .field-name-field-fc-feature .field-item h4:before {
      margin: 0;
      padding: 0;
      display: block;
      position: absolute;
      left: 0;
      top: -1rem;
      color: #e6e6e6;
      font-weight: 300;
      overflow: hidden;
      font-size: 62.5px;
      height: 62.5px;
      line-height: 62.5px; }
  .field-name-field-fc-feature .field-item .desc {
    min-height: 6rem; }

.field-name-field-fc-feature .field-item:nth-child(1) h4:before {
  content: "01|"; }

.field-name-field-fc-feature .field-item:nth-child(2) h4:before {
  content: "02|"; }

.field-name-field-fc-feature .field-item:nth-child(3) h4:before {
  content: "03|"; }

.field-name-field-fc-feature .field-item:nth-child(4) h4:before {
  content: "04|"; }

.field-name-field-fc-feature .field-item:nth-child(5) h4:before {
  content: "05|"; }

.field-name-field-fc-feature .field-item:nth-child(6) h4:before {
  content: "06|"; }

.field-name-field-fc-feature .field-item:nth-child(7) h4:before {
  content: "07|"; }

.field-name-field-fc-feature .field-item:nth-child(8) h4:before {
  content: "08|"; }

#page-top {
  padding: 3rem 0 1rem;
  background: #2e2e2e; }

#page-main {
  padding: 3rem 0; }
  .not-front #page-main {
    background: #f2f2f2; }

#page-bottom {
  background: #ededed; }

#box-top {
  background: #ededed; }
  #box-top a, #box-top i {
    color: #f66838; }
  #box-top .region-box-top {
    float: left;
    width: 80%; }
    #box-top .region-box-top:before, #box-top .region-box-top:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
      float: none; }
  #box-top .main-email {
    font-size: 90%;
    padding: 0.5rem 0 0; }

#block-follow-site {
  float: left; }
  #block-follow-site .follow-link-wrapper {
    display: block;
    float: left; }
    #block-follow-site .follow-link-wrapper a {
      display: block;
      float: none;
      text-indent: -9999px;
      border-left: 1px solid white;
      padding: 0;
      margin: 0;
      width: 37px;
      height: 37px;
      background: none;
      position: relative; }
      #block-follow-site .follow-link-wrapper a:before {
        position: absolute;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: block;
        width: 33px;
        height: 33px;
        text-indent: 0;
        padding: 0.75rem;
        text-align: center; }
      #block-follow-site .follow-link-wrapper a.follow-link-facebook:before {
        content: ""; }
      #block-follow-site .follow-link-wrapper a.follow-link-twitter:before {
        content: ""; }
      #block-follow-site .follow-link-wrapper a.follow-link-googleplus:before {
        content: ""; }
      #block-follow-site .follow-link-wrapper a.follow-link-youtube:before {
        content: ""; }
    #block-follow-site .follow-link-wrapper:last-child a {
      border-right: 1px solid white; }

#block-locale-language-content {
  float: right; }
  #block-locale-language-content .language-switcher-locale-url {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none; }
    #block-locale-language-content .language-switcher-locale-url:before, #block-locale-language-content .language-switcher-locale-url:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
      float: none; }
    #block-locale-language-content .language-switcher-locale-url li {
      margin-top: 0.75rem;
      display: block;
      float: left;
      border-right: 1px solid #aeaeae; }
      #block-locale-language-content .language-switcher-locale-url li:last-child {
        border: none; }
      #block-locale-language-content .language-switcher-locale-url li a, #block-locale-language-content .language-switcher-locale-url li span {
        color: #2e2e2e;
        font-size: 90%;
        display: block;
        padding: 0 1rem 1px; }
        #block-locale-language-content .language-switcher-locale-url li a.active, #block-locale-language-content .language-switcher-locale-url li span.active {
          color: #f66838; }

#logo {
  padding: 2rem 0 3rem; }

#box-middle .main-phone {
  margin-top: 3rem;
  border: 2px solid #ededed;
  display: block;
  font-size: 25px;
  line-height: 25px;
  -webkit-border-radius: 0.1rem;
  -khtml-border-radius: 0.1rem;
  -moz-border-radius: 0.1rem;
  -ms-border-radius: 0.1rem;
  -o-border-radius: 0.1rem;
  border-radius: 0.1rem; }
  #box-middle .main-phone i {
    color: #f66838;
    padding: 1.5rem 2rem; }
  #box-middle .main-phone span {
    padding: 1.5rem 2rem;
    border-left: 2px solid #ededed; }

#box-bottom .box-header-stickup {
  background: #f66838;
  padding: 0;
  margin: 0; }
  #box-bottom .box-header-stickup.isStuck {
    z-index: 200; }

#block-bannertop-my-bannertop .bx-wrapper,
#block-bannertop-my-bannertop .bx-viewport {
  margin: 0;
  padding: 0;
  display: block;
  border: none;
  left: 0;
  -webkit-box-shadow: 0 0 0 0 black;
  -moz-box-shadow: 0 0 0 0 black;
  box-shadow: 0 0 0 0 black; }

#block-bannertop-my-bannertop .banner-slider {
  margin: 0;
  padding: 0;
  display: block;
  list-style: none; }
  #block-bannertop-my-bannertop .banner-slider li img {
    width: 100%; }
  #block-bannertop-my-bannertop .banner-slider li .image-sm {
    display: none; }
  #block-bannertop-my-bannertop .banner-slider li .description {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    color: white; }
    #block-bannertop-my-bannertop .banner-slider li .description h5, #block-bannertop-my-bannertop .banner-slider li .description p {
      font-weight: 700;
      text-transform: uppercase;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.6); }
    #block-bannertop-my-bannertop .banner-slider li .description h5 {
      font-size: 50px;
      font-weight: 700; }
    #block-bannertop-my-bannertop .banner-slider li .description p {
      font-weight: 300;
      font-size: 25px; }
    #block-bannertop-my-bannertop .banner-slider li .description .box-wraper {
      text-align: center;
      margin-top: 10rem; }
      #block-bannertop-my-bannertop .banner-slider li .description .box-wraper.container-fluid {
        width: 80%;
        margin: 4rem auto; }

#footer {
  background: #2e2e2e;
  color: #7b7b7b;
  font-size: 14px;
  padding: 1rem 0; }
  #footer h3 {
    color: white;
    font-size: 19px;
    text-transform: uppercase; }

#footer-columns {
  background: url("../logo-bottom.svg") no-repeat right 3rem; }

#block-footerinfo-footerinfo .item i, #block-footerinfo-footerinfo .item span {
  color: #f66838; }

#block-footerinfo-footerinfo ul.menu {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none; }
  #block-footerinfo-footerinfo ul.menu li {
    display: block;
    margin: 0 0 0.5rem;
    padding: 0; }
    #block-footerinfo-footerinfo ul.menu li ul {
      display: none !important;
      visibility: hidden !important;
      height: 0 !important; }
    #block-footerinfo-footerinfo ul.menu li a {
      color: white;
      display: inline-block; }
      #block-footerinfo-footerinfo ul.menu li a:before {
        -webkit-transition: all 0.4s ease 0s;
        -khtml-transition: all 0.4s ease 0s;
        -moz-transition: all 0.4s ease 0s;
        -ms-transition: all 0.4s ease 0s;
        -o-transition: all 0.4s ease 0s;
        transition: all 0.4s ease 0s;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: '\f18e';
        margin-right: 1rem;
        color: #484848; }
      #block-footerinfo-footerinfo ul.menu li a:hover:before {
        margin-right: 1.5rem; }

#block-views-bottompage-block .view-content {
  padding: 4rem 0; }

#block-views-employee-block {
  background: #2e2e2e;
  color: #b3b3b3;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  #block-views-employee-block a {
    color: white; }
  #block-views-employee-block .view-header h3, #block-views-employee-block .view-header h5 {
    color: white;
    text-transform: uppercase;
    margin: 0 0 0.5rem;
    font-weight: 600;
    font-family: 'Raleway', sans-serif; }
  #block-views-employee-block .view-header h3 {
    font-size: 25px; }
  #block-views-employee-block .view-header h5 {
    font-size: 20px;
    color: #f66838; }
  #block-views-employee-block .view-content {
    margin-top: 2rem; }

#block-webform-client-block-19 {
  background: #2e2e2e;
  padding: 4rem 0;
  color: white; }
  #block-webform-client-block-19 i {
    font-size: 60px; }
  #block-webform-client-block-19 h3 {
    margin: 0.5rem 0 2rem 2rem;
    font-size: 25px;
    font-weight: 700;
    text-transform: uppercase; }

#views-csgallery-grid-1 .col-md-6 .node-offer.node-teaser a.header {
  position: relative; }
  #views-csgallery-grid-1 .col-md-6 .node-offer.node-teaser a.header:before {
    display: block;
    position: absolute;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 2rem;
    height: 2rem;
    top: 1;
    left: 0;
    color: white;
    font-size: 60px; }

#views-csgallery-grid-1 .col-md-6:first-child a.header:before {
  content: ""; }

#views-csgallery-grid-1 .col-md-6:last-child a.header:before {
  content: ""; }

#box-paralax {
  font-size: 20px; }
  #box-paralax h5 {
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700; }
  #box-paralax h5, #box-paralax p {
    text-align: center;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6); }

.node.node-full .field-name-field-fc-feature .field-item {
  min-height: 25rem; }
  .node.node-full .field-name-field-fc-feature .field-item h4 {
    margin: 0 0 1.5rem;
    padding: 0; }
    .node.node-full .field-name-field-fc-feature .field-item h4:before {
      display: none; }

.node.node-partners.node-token, .node.node-offer.node-token {
  overflow: hidden; }
  .node.node-partners.node-token .content, .node.node-offer.node-token .content {
    position: relative;
    overflow: hidden;
    display: block; }
    .node.node-partners.node-token .content:before, .node.node-offer.node-token .content:before {
      position: absolute;
      display: block;
      content: ' ';
      width: 100%;
      height: 100%;
      background-color: black;
      /* The Fallback */
      background-color: rgba(0, 0, 0, 0.3); }
  .node.node-partners.node-token .desc, .node.node-offer.node-token .desc {
    padding: 0;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    color: white; }
    .node.node-partners.node-token .desc .desc-wrap, .node.node-offer.node-token .desc .desc-wrap {
      padding: 36% 3rem;
      text-align: center; }
    .node.node-partners.node-token .desc .body-short, .node.node-offer.node-token .desc .body-short {
      opacity: 0;
      visibility: hidden; }
  .node.node-partners.node-token .desc,
  .node.node-partners.node-token .desc-wrap,
  .node.node-partners.node-token .body-short, .node.node-offer.node-token .desc,
  .node.node-offer.node-token .desc-wrap,
  .node.node-offer.node-token .body-short {
    -webkit-transition: 0.5s;
    -khtml-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s; }
  .node.node-partners.node-token:hover .desc, .node.node-offer.node-token:hover .desc {
    background: #f66838; }
  .node.node-partners.node-token:hover .desc-wrap, .node.node-offer.node-token:hover .desc-wrap {
    padding: 15% 3rem;
    text-align: center; }
  .node.node-partners.node-token:hover .body-short, .node.node-offer.node-token:hover .body-short {
    opacity: 1;
    visibility: visible; }
  .node.node-partners.node-token h3, .node.node-offer.node-token h3 {
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    word-wrap: break-word;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.6); }

.node h6 {
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase; }

.node ul.marked-list {
  display: block;
  margin: 2rem 0;
  list-style: none;
  padding: 0; }
  .node ul.marked-list li {
    display: block;
    position: relative;
    padding-left: 2.5rem;
    margin-bottom: 0.75rem; }
    .node ul.marked-list li:before {
      display: block;
      position: absolute;
      left: 0;
      top: 0.4rem;
      content: '\f18e';
      color: #f66838;
      display: inline-block;
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }

.gallery-item .node-partners {
  border: 1px solid #f5f5f5;
  background: white;
  margin-bottom: 4rem; }
  .gallery-item .node-partners.node-token {
    background: none;
    border: none;
    text-align: center; }
    .gallery-item .node-partners.node-token img {
      display: inline-block; }
  .gallery-item .node-partners .logo-thumb {
    overflow: hidden; }
  .gallery-item .node-partners .desc {
    padding: 2rem; }
  .gallery-item .node-partners h3 {
    margin: 0 0 2rem;
    font-weight: 700;
    color: #f66838; }
  .gallery-item .node-partners p {
    height: 9.5rem;
    overflow: hidden; }
  .gallery-item .node-partners img {
    -webkit-transition: all 0.4s ease 0s;
    -khtml-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
  .gallery-item .node-partners:hover img {
    transform: scale(1.2) rotate(-2deg);
    opacity: 0.8; }

.node-offer.node-teaser {
  color: #7b7b7b; }
  .node-offer.node-teaser a.header {
    display: block;
    padding-left: 8rem;
    margin-bottom: 2rem; }
  .node-offer.node-teaser h3, .node-offer.node-teaser h5 {
    margin: 0 0 0.5rem;
    font-size: 25px;
    text-transform: uppercase; }
  .node-offer.node-teaser h3 {
    color: white;
    font-weight: 700; }
  .node-offer.node-teaser h5 {
    color: #f66838; }

.node-offer.node-full h5 {
  margin: 0 0 0.5rem;
  font-size: 17px;
  text-transform: uppercase;
  color: #f66838;
  font-weight: 700; }

.node-offer.node-rss {
  border: 1px solid #e5e5e5;
  color: #7b7b7b; }
  .node-offer.node-rss h4 {
    color: #f66838;
    font-size: 19px;
    font-weight: 700;
    text-transform: uppercase; }
  .node-offer.node-rss img {
    -webkit-transition: all 0.4s ease 0s;
    -khtml-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s; }
  .node-offer.node-rss .offer-image-thumb {
    overflow: hidden;
    text-align: center;
    background: white; }
    .node-offer.node-rss .offer-image-thumb img {
      display: inline-block; }
  .node-offer.node-rss .offer-detail {
    background: white;
    padding: 2rem;
    font-weight: 200;
    font-size: 17px;
    padding-bottom: 3rem; }
  .node-offer.node-rss:hover img {
    transform: scale(1.2) rotate(-2deg);
    opacity: 0.8; }

.contact-info ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.contact-info h5 {
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  color: #f66838; }

.contact-info p {
  font-weight: 400;
  text-transform: inherit; }

.contact-info li {
  /*
    dl:last-child dt {
      min-width: auto;
    }
    */ }
  .contact-info li p + dl {
    margin-top: 3px;
    margin-bottom: 3px; }
  .contact-info li dt,
  .contact-info li dd {
    display: inline-block;
    font-weight: 400; }
  .contact-info li dt {
    min-width: 100px;
    vertical-align: top; }
    .contact-info li dt:after {
      content: ":"; }
  .contact-info li a:hover {
    color: #f7682c; }
  .contact-info li a[href^="mailto"] {
    color: #f7682c;
    text-decoration: underline; }
  .contact-info li a[href^="mailto"]:hover {
    color: #2e2e2e; }
  @media (min-width: 768px) {
    .contact-info li dt {
      min-width: 100px; } }

.node-employee.node-teaser h3 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 20px;
  font-weight: 600;
  color: white; }

@media (min-width: 1199px) and (max-width: 1600px) {
  #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper {
    margin-top: 5rem; } }

@media (min-width: 992px) and (max-width: 1199px) {
  #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper {
    margin-top: 2rem; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper h5 {
      font-size: 40px; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper p {
      font-size: 20px; } }

@media (max-width: 991px) {
  #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper {
    margin-top: 1rem; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper h5 {
      font-size: 30px;
      margin: 0 0 5px; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper p {
      font-size: 15px;
      margin: 5px 0; } }

@media (max-width: 768px) {
  #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper {
    margin-top: 1rem; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper h5 {
      font-size: 25px; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper p {
      font-size: 12.5px; }
    #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper .read-more-button-invert {
      padding: 0.5rem 2rem;
      font-size: 100%;
      margin: 1rem 0; }
  #box-bottom .box-header-stickup {
    display: none; }
  #footer-columns .region-footer-firstcolumn {
    max-width: 60%;
    margin: 0 auto; }
  .field-name-field-fc-feature .field-items {
    display: block;
    flex-wrap: inherit; }
  .field-name-field-fc-feature .field-item {
    flex: inherit; }
    .field-name-field-fc-feature .field-item:nth-child(odd), .field-name-field-fc-feature .field-item:nth-child(even) {
      padding-left: 0;
      padding-right: 0; }
  .node.node-full .field-name-field-fc-feature .field-item {
    min-height: inherit; } }

@media (max-width: 568px) {
  #block-bannertop-my-bannertop #block-bannertop-slider .banner-slider .box-wraper .read-more-button-invert {
    display: none; }
  #footer-columns .region-footer-firstcolumn {
    max-width: 80%; } }

@media (max-width: 468px) {
  #block-bannertop-my-bannertop {
    display: none; } }
