// colors
$black: #2e2e2e;
$black-font: lighten($black,30%);
$white-font: darken(white,10%);
$grey: #ededed;
$orange: #f66838;
$hover-color: #f66838;
.a-left {
  float: left;
}
.a-right {
  float: right;
}
.page-brake-wborder {
  height: 1px;
  width: 100%;
  margin: 1rem 0;
  background: lighten($black,5%);
}
.read-more-button-invert,
.read-more-button {
  display: inline-block;
  margin: 2rem 0;
  color: white;
  padding: 0.75rem 2rem;
  text-transform: uppercase;
}
.read-more-button {
  border: 1px solid $black-font;
  @include buton-read-more();
  @include border-radius(2px);
}
.read-more-button-invert {
  border: 2px solid $orange;
  @include buton-read-more-invert();
  @include border-radius(4px);
}
.wall-grid-item {
  display: block;
  width: 25%;
  height: auto;
  float: left;
  overflow: hidden;
}

#maps-wraper {
  min-height: 32rem;
}
#box-paralax {
  padding-top: 12rem;
  padding-bottom: 12rem;
  color: white;
  background-attachment: fixed;
  background-size: cover;
}