#views-csgallery-grid-1 {
  .col-md-6 {
    .node-offer.node-teaser {
      a.header {
        position: relative;
        &:before {
          display: block;
          position: absolute;
          @include fontAwesome;
          width: 2rem;
          height: 2rem;
          top: 1;
          left: 0;
          color: white;
          font-size: $font-header-big + 10px;
        }
      }
    }
    &:first-child {
      a.header:before {
        content: "";
      }

    }
    &:last-child {
      a.header:before {
        content: "";
      }
    }
  }
}
#box-paralax {
  font-size: $font-header-small;
  h5 {
    font-size: $font-header-big;
    text-transform: uppercase;
    font-weight: 700;
  }
  h5, p {
    text-align: center;
    @include text-shadow(0,0,5px,rgba(0,0,0,.6));
  }
}