// bootstrap overlay
// ====

@mixin boot-menu($block-align:'left',$font-s:16px,$back0:'none',$back1:white,$radius:0) {
  background-color: $back0;
  .navbar-collapse {
    margin: 0;
    padding: 0;
    border: none;
    overflow-y: hidden;
    @if $block-align == "left" {
      float: left;
      @media (max-width: 992px)  {
        float: none;
      }
    } @else if $block-align == "right" {
      float: right;
      @media (max-width: 992px)  {
        float: none;
      }
    } @else if $block-align == "center" {
      ul.menu {
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        margin: 0 auto;
        @media (max-width: 767px)  {
          width: inherit;
          margin: 0 15px;
        }
      }
    }
    & > ul.menu > li:hover {
      & > ul {
        display: block;
      }
    }
  }
  ul.menu {
    @include clearfix();
    margin: 0;
    padding: 0;
    display: block;
    li {
      float: left;
      margin: 0;
      padding: 0;
      display: block;
      position: relative;
      span,
      a {
        font-size: $font-s;
        display: block;
      }
      ul {
        display: none;
        position: absolute;
        z-index: 20;
        @include border-radius($radius);
        @include background-opacity($back1,0.75);
        @include transition(all 0.4s ease 0s);
        @include box-shadow(0px, 5px, 10px, 0px, rgba(50, 50, 50, 0.2));
        li {
          float: none;
          ul {
            display: none;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .navbar-collapse,
    ul.menu {
      float: none;
    }
    ul.menu {
      li {
        float: none;
        a {
          text-align: center;
        }
        ul {
          display: block;
          position: inherit;
        }
      }
    }
  }
}

