#page-top {
  padding: 3rem 0 1rem;
  background: $black;
}
#page-main {
  padding: 3rem 0;
  .not-front & {
    background: $white-gray;
  }
}
#page-bottom {
  background: $grey;
}