.node-offer {
  &.node-teaser {
    color: $black-font;
    a.header {
      display: block;
      padding-left: 8rem;
      margin-bottom: 2rem;
    }
    h3, h5 {
      margin: 0 0 0.5rem;
      font-size: $font-header-med;
      text-transform: uppercase;
    }
    h3 {
      color: white;
      font-weight: 700;
    }
    h5 {
      color: $orange;
    }
  }
  &.node-full {
    h5 {
      margin: 0 0 0.5rem;
      font-size: $font-base;
      text-transform: uppercase;
      color: $orange;
      font-weight: 700;
    }
  }
  &.node-rss {
    border: 1px solid darken($white-gray,5%);
    color: $black-font;
    h4 {
      color: $orange;
      font-size: $font-base + 2px;
      font-weight: 700;
      text-transform: uppercase;
    }
    img {
      @include transition(all 0.4s ease 0s);
    }
    .offer-image-thumb {
      overflow: hidden;
      text-align: center;
      background: white;
      img {
        display: inline-block;
      }
    }
    .offer-detail {
      background: white;
      padding: 2rem;
      font-weight: 200;
      font-size: $font-base;
      padding-bottom: 3rem;
    }
    &:hover {
      img {
        transform: scale(1.2) rotate(-2deg);
        opacity: 0.8;
      }
    }
  }
}
